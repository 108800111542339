.type-panel {
    display: none;
    z-index: 10;
    width: 100%;
    position: absolute;
    border-radius: 8px;

    background-color: white;

    &.open {
        display: flex;
        flex-direction: column;
    }
}
