@import './node_modules/bootstrap/scss/functions';

/* Colors */
$primary:     #00507B;
$secondary:   #707070;
$warning:     #ffc107;
$danger:      #D65656;
$info:        #5EB046;
$success:     #198754;
$blue:        #0d6efd;
// Grayscale
$white:       #ffffff;  // https://getbootstrap.com/docs/5.0/utilities/background/
$gray-100:    #f8f9fa;  // Grayscale colors are also available,
$gray-200:    #e9ecef;  // but only a subset are used to generate any utilities.
$gray-300:    #dee2e6;  // note(joeysapp): Include them so we can use them, e.g. bg-gray-200
$gray-400:    #ced4da;  //                 instead of "lightest-light-lighter-grey"
$gray-500:    #adb5bd;
$gray-600:    #6c757d;
$gray-700:    #495057;
$gray-800:    #343a40;
$gray-900:    #212529;
$black:       #000000;
// Custom
$primaryFocus: #1299CC;
$primaryFocusHover: #E5F8FF;
$primaryFocusShadow: rgb(13 150 204 / 25%);

$theme-colors: (
  "primary":    $primary,
  "secondary":  $secondary,
  "warning":    $warning,
  "danger":     $danger,
  "info":       $info,
  "success":    $success,
  "blue":       $blue,
  "light":      $gray-100,
  "dark":       $gray-900,
);
$custom-colors: (
  "primaryFocus":        $primaryFocus,
  "primaryFocusHover":   $primaryFocusHover,
  "primaryFocusShadow":  $primaryFocusShadow,
  "white":               $white,
  "gray-100":            $gray-100,
  "gray-200":            $gray-200,
  "gray-300":            $gray-300,
  "gray-400":            $gray-400,
  "gray-500":            $gray-500,
  "gray-600":            $gray-600,
  "gray-700":            $gray-700,
  "gray-800":            $gray-800,
  "gray-900":            $gray-900,
  "black":               $black
);
$theme-colors: map-merge($theme-colors, $custom-colors);
$spacers: (
  0: 0em,
  1: .25em,
  2: .50em,
  3: 1em,
  4: 1.5em,
  5: 3em,
  6: 6em,
  7: 12em,
);
$font-sizes: (
  1: 36px,
  2: 24px,
  3: 21px,
  4: 18px,
  5: 15px,
  6: 13px,
);
$progress-bg: $gray-400;

// Import base stylings *after* map-merges
@import './node_modules/bootstrap/scss/bootstrap';

// Bootstrap overrides/extensions
.text-serif { font-family: 'Georgia'; }
.shadow-sm { box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.3) !important; }
.rounded { border-radius: 6px !important; }
.form-label { margin-bottom: 0.25rem; }
.form-control {
  border-radius: 8px;
  border-color: $gray-600;
  line-height: 1;
  height: 50px;

  &:focus { border-color: $primaryFocus; }
  &.form-error { border-color: $danger }
}
.btn {
  line-height: 1;
  height: 50px;
  border-radius: 8px;
}
.btn-primary {
  &:focus {
    color: white;
    background-color: $primary;
  }
}
