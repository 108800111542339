.text-input-errors {
  white-space: nowrap;
  line-height: 21px;
  min-height: 21px;
  overflow-y: clip;
  transition: 1s all;
  opacity: 0;
}
.text-input-errors.show {
  opacity: 1;
}
