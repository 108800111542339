.pbar-bg {
  background-color: #1399CC !important;
}

.heading {
  margin-top: 1.5em !important;
}

.subhead-highlight {
  border-left: 2px solid #0099CC;
  background-color: #E5F8FF;
}

.form-control {
  height: 40px !important;
}

.form-select {
  border-radius: 8px !important;
  height: 40px !important;
}

.react-date-picker__wrapper {
  border: unset !important;
};

.input-row {
  margin-bottom: 1.5em;
}

.title-row {
  margin-bottom: 0.5em;
}

.custom-nav-button {
  height: 40px !important;
  padding-right: 4em !important;
  padding-left: 4em !important;
}

.custom-link-button {
  padding-left: 0px !important;
}

.footer-margin {
  margin-bottom: 100px;
}

.yn-button {
  width: 100px;
  height: 45px !important;
  border-radius: 8px;
  border: solid 1px var(--bs-gray-500);
  background-color: transparent;
}

.yn-button:hover {
  background-color: var(--bs-gray-100);
}

.yn-button-hl {
  border: solid 2px var(--bs-gray-500);
  background-color: var(--bs-gray-300);
}

.collapse-indent {
  border-left: solid 3px var(--bs-gray-400);
  padding-left: 1.5em !important;
  margin-left: 1em;
  margin-bottom: 1.5em;
}

.payment-box {
  background-color: var(--bs-gray-200);
  padding: 2em;
}

.confirmation-box {
  background-color: white;
  border: 1px solid var(--bs-gray-300);
}
