.mmb-navbar {
  width: 100%;

  position: fixed;
  top: 0px;
  left: 0px;

  display: flex;
  flex-direction: column;
  user-select: none;
}

// Hoverable/clickable element with FontAwesomeIcon inside it
.navbar-icon {
  height: 100%;
  width: var(--navbarHeight); // equal spacing

  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  color: black;
  cursor: pointer;
  background-color: white;

  // Inset box-shadow doesn't displace element upward like a border would
  box-shadow: 0 -3px 0 transparent inset;
  transition: all var(--hoverTiming) var(--hoverAnimation);

  &.selected, &:hover, &.open {
    color: var(--bs-primaryFocus);
    box-shadow: 0 -3px 0 var(--bs-primaryFocus) inset;
  }
}

/* content inside dropdown */
.navbar-dropdown {
  position: absolute;
  display: none;
  flex-direction: column;

  top: calc(var(--navbarHeight) + 4px);
  right: 0px;

  background-color: white;
  color: black;
  cursor: auto;
  white-space: nowrap;

  &.open {
    display: flex;
  }
}
