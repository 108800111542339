@import './App.bs.scss';

/* Overall styling & common classes */
body {
  font-family: 'Helvetica', -apple-system, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  color: $dark;
  overflow-y: scroll;
}

.mmb-container {
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;
  height: calc(100vh - var(--navbarHeight));
  margin-top: var(--navbarHeight);

  overflow-x: hidden;

  &.show-agent {
      margin-top: calc(var(--navbarHeight) + var(--navbarAgentHeight));
      height: calc(100vh - var(--navbarHeight) - var(--navbarAgentHeight));
  }
}

/* quote/plan cards view logic for mobile */
.card {
    max-width: 700px;
    width: 700px;

    margin-left: 12px;
    margin-right: 12px;
}
@media (max-width: 750px) {
    .card {
        max-width: 100%;
        width: 100%;
        margin-left: 0;
        margin-right: 0;
    }
}

// ensure landing splash image looks nice horiz/vert on mobile/desktop
// * /landing/VerifyIdentity
// * /recommendation/RecLanding
#landing-img {
    width: 50%;
    overflow: hidden;

    img {
        height: 100%;
        min-height: 500px;
        right: 0px;
    }
}
#landing-text {
    width: 50%;
}
@media (max-width: 750px) {
    #landing-img {
        width: 100%;
        height: 200px;

        img {
            height: auto;
            min-height: auto;
            width: 100%;
            min-width: 500px;
            bottom: -25%;
        }
    }
    #landing-text {
        width: 100%;
    }
}


/* Variables, utility */
$mobileWidth: 750px;
$navbarHeight: 60px;
$navbarAgentHeight: 85px;

$hoverTiming: 250ms;
$hoverAnimation: cubic-bezier(.25, .46, .45, .94);
$showTiming: 250ms;
$showAnimation: cubic-bezier(.25, .46, .45, .94);

// https://sass-lang.com/documentation/at-rules/function
@function showTiming($lineCount) {
    $result: 0ms;
    @for $_ from 1 through $lineCount {
        $result: $result + 5000ms;
    }
    @return $result;
}
/* animate showing an element */
.s-text {
  transition:
    // only transition colors so the elem won't glide around on nav
    max-height $showTiming $showAnimation,
    opacity $showTiming $showAnimation;
  overflow: hidden;  
  max-height: 0px;
  opacity: 0;

  &.show {
    max-height: 1rem;
    opacity: 1;
    // Sizes of elements to "show"
    // Examples of old usage: agent-frontend/plans/details/ (opening/closing divs)
    &-0 { max-height: 1rem !important; animation-duration: showTiming(1); }
    &-1 { max-height: 5rem !important; animation-duration: showTiming(5); }
    &-2 { max-height: 15rem !important; animation-duration: showTiming(15); }
    &-3 { max-height: 50rem !important; anumation-duration: showTiming(50); }
    &-4 { max-height: 100rem !important; animation-duration: showTiming(100); }
  }
}
/* bootstrap doesn't have an idiomatic hover class */
.cursor-pointer { cursor: pointer; }
.h-link {
  transition:
    background-color $hoverTiming $hoverAnimation,
    color $hoverTiming $hoverAnimation,
    border $hoverTiming $hoverAnimation;
  border: 1px solid transparent; // allow pre-defined borders
  border-radius: 8px;
  cursor: pointer;

  &:hover, &focus {
    background-color: $primaryFocusHover !important;
    border: 1px solid $primaryFocus !important;

    // apply color to inner FontAwesome elements
    // .h-fa {
    //   color: $primaryFocus !important;
    // }
  }

  &.disabled {
    opacity: 0.25;
    cursor: initial;

    &:hover, &focus {
      background-color: transparent !important;
      border: 1px solid transparent !important;
    }
  }
}
.h-text {
  transition: background-color $hoverTiming $hoverAnimation;
  border-radius: 8px;
  cursor: pointer;

  &:hover, &.focus {
    background-color: $gray-200 !important;
  }
}


// SassScript interpolation - now usable via var(--name)
:root {
  --mobileWidth: #{$mobileWidth};

  --navbarHeight: #{$navbarHeight};
  --navbarAgentHeight: #{$navbarAgentHeight};

  --hoverTiming: #{$hoverTiming};
  --hoverAnimation: #{$hoverAnimation};
}

.text-link {
    font-weight: 1000;
    color: $blue;
    text-decoration: underline;
    cursor: pointer;
}
.bg-success-light {
  background-color: rgb(207, 234, 213);
}
/* row in desktop view, column in mobile view */
.flex-m-row-col {
    flex-direction: row;
}

@media (max-width: 750px) {
    .flex-m-row-col {
        flex-direction: column;
    }
}
