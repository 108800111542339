/* dropdown */
.dd {
    max-height: 64px;
    overflow: hidden;
    transition: all 0.5s ease-in-out;

    &.open {
        max-height: 1024px;
    }
}

.dd-header {
    height: 64px;
    background-color: white;
    transition: all var(--hoverTiming) var(--hoverAnimation);
    cursor: pointer;

    position: relative;
    z-index: 10;

    &:hover {
        background-color: var(--bs-primaryFocusHover);
    }
}
