/* desktop view */
// place button to right of card
.qc-container {
    flex-direction: row;
}
.qc-btn {
    margin-top: 0px;
    margin-left: 24px;
    min-width: 240px;
}

/* mobile view */
@media (max-width: 1000px) {
  // place button below card and fill width
  .qc-container {
      flex-direction: column;
  }
  .qc-btn {
      margin-top: 24px;
      margin-left: 0px;
      width: 100%;
      min-width: 100%;
  }
}
